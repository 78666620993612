/**
 * Main JS
 */

function mySteps() {
  const listElements = document.querySelectorAll(".js-steps li");
  const illustrationElements = document.querySelectorAll("._illustrations img");

  console.log("listElements: ", listElements);
  console.log("illustrationElements: ", illustrationElements);

  if (listElements.length > 0) {
    // Continue with logic if elments are found in the DOM
    for (let i = 0; i < listElements.length; i++) {
      listElements[i].addEventListener("mouseover", function () {
        const current = document.querySelector(".js-steps li.is-active");

        if (current) {
          current.classList.remove("is-active");
        }

        

        this.classList.add("is-active");

        // add same css class on illustration
        if (illustrationElements.length > 0) {
          const currentIllustration = document.querySelector("._illustrations .is-active");
          if (currentIllustration) {
            currentIllustration.classList.remove("is-active");
          }
          illustrationElements[i].classList.add("is-active");
        }
        
      });
      
    }
  }
}

// Invoke function when DOM is loaded (Document ready)
document.addEventListener("DOMContentLoaded", mySteps());
